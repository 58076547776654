var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"menu"},[_c('div',{staticClass:"menu__upper"},[_c('div',{staticClass:"menu__info",on:{"click":function($event){return _vm.$router.push('/profile')}}},[_c('UserAvatar',{attrs:{"image":_vm.user.photo}}),_vm._v(" "),_c('div',{staticClass:"menu__headline"},[_c('div',{staticClass:"menu__name"},[_vm._v("\n          "+_vm._s(_vm.user.username)+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"menu__email"},[_vm._v("\n          "+_vm._s(_vm.user.email)+"\n        ")])]),_vm._v(" "),_c('div',{staticClass:"menu__logout-mobile",on:{"click":_vm.logOut}},[_vm._v("\n        "+_vm._s(_vm.translations.buttons.log_out)+"\n      ")])],1),_vm._v(" "),_c('div',{staticClass:"menu__list-wrapper"},[_c('div',{staticClass:"menu__list"},[_c('router-link',{attrs:{"to":{ name: 'Specification' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('a',{staticClass:"menu__elem",class:{'menu__elem--active' : isActive},attrs:{"href":href},on:{"click":navigate}},[_vm._v("\n            "+_vm._s(_vm.translations.titles.specifications)+"\n          ")])]}}])}),_vm._v(" "),(_vm.user.approved)?_c('router-link',{attrs:{"to":{ name: 'Three-models' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('a',{staticClass:"menu__elem",class:{'menu__elem--active' : isActive},attrs:{"href":href},on:{"click":navigate}},[_vm._v("\n            "+_vm._s(_vm.translations.titles.models_3d)+"\n          ")])]}}],null,false,2541495360)}):_vm._e(),_vm._v(" "),(_vm.user.approved)?_c('router-link',{attrs:{"to":{ name: 'Manuals' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('a',{staticClass:"menu__elem",class:{'menu__elem--active' : isActive},attrs:{"href":href},on:{"click":navigate}},[_vm._v("\n            "+_vm._s(_vm.translations.titles.manuals)+"\n          ")])]}}],null,false,3987414365)}):_vm._e(),_vm._v(" "),_c('router-link',{attrs:{"to":{ name: 'Video-gallery' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('a',{staticClass:"menu__elem",class:{'menu__elem--active' : isActive},attrs:{"href":href},on:{"click":navigate}},[_vm._v("\n            "+_vm._s(_vm.translations.titles.video_gallery)+"\n          ")])]}}])})],1)])]),_vm._v(" "),_c('div',{staticClass:"menu__under"},[_c('div',{staticClass:"menu__logout",on:{"click":_vm.logOut}},[_vm._v("\n      "+_vm._s(_vm.translations.buttons.log_out)+"\n    ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }